@import '../../../@theme/variables';

.taginput {
  align-items: center;
  background-color: $white;
  display: flex;
  flex-direction: column;
  max-width: 580px;
  &-container {
    display: inline-block;
    max-width: 580px;
    width: 100%;
    .form-control {
      border: none !important;
      background-color: $white !important;
      display: inline-block !important;
      padding: none !important;
      margin: 8px;
      width: auto !important;
    }
  }
  &-separator {
    box-sizing: border-box;
    border: 1px solid $gray-3;
    background-color: $gray-3;
    max-height: 1px;
    width: 100%;
    max-width: 580px;
  }

  .list-group {
    max-height: 200px;
    overflow-y: auto;
    width: auto;
    &__item {
      display: flex;
      flex-direction: row;
      background-color: $white;
      padding: 4px 17px;
      height: 43px;
      width: auto;
      &:hover {
        cursor: pointer;
        background-color: $gray-1;
      }
    }
  }
}
