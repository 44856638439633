@import '../../@theme/variables';

.login {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  background-color: $black;
  &__content {
    position: relative;
  }
  &__box-form {
    height: 100vh;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    &-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: rgba(0, 0, 0, 0.89);
      border-radius: $border-radius;
      width: 450px;
      padding: 57px;
      img {
        display: flex;
        margin: 6px auto;
        height: 65px;
        min-height: 50px;
        min-width: 100px;
      }
      .btn {
        color: $white;
        font-size: $font-size-200;
        font-weight: normal;
        width: 18rem;
        height: 3rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin: auto;
      }
    }
  }
}
