@import '../../@theme/variables';

.main-content {
  .menu {
    width: 100%;
    padding-left: 3.125rem;
    margin-bottom: 0;
    border-bottom: 1px solid $gray-2;
    position: sticky;
    top: 4.375rem;
    background-color: $gray-1;
    &-nav {
      .nav-link {
        color: $gray-5;
        padding: 14px;
      }
      .active {
        border-bottom: 2px solid $red;
        font-weight: bold;
        color: $black;
      }
    }
  }
  .users-container {
    padding: 0 2.5rem 1.875rem;
  }
}
