@import '~lighthouse-ui-core-stencil/dist/collection/styles/global';

.container-fluid {
  background-color: $gray-1;
  flex: 1;
}

.small-width {
    min-width: 100px;
  i {
    margin-right: 10px;
  }
}

.notification-title{
  margin-bottom: 3px;
}


a{
  color: $red;
  &:hover{
    color: $gold;
  }
}

.toast {
  height: auto;
}


tbody {
  border: 1px solid $gray-2;
  color: $gray-6;
  td {
    text-align: left;
    vertical-align: middle;
    padding: 0.938rem;
    border-top: 1px solid $gray-2;
    &:first-of-type{
      padding-left: 1.25rem;
    }
  }
}


.loading-indicator:before {
  content: '';
  background-color: rgba(235, 226, 226, 0.5);
  background-image: url('../assets/img/Logos-Jauht-mini.svg');
  background-repeat: no-repeat;
  background-position: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.loading-indicator:after {
  font-weight: bold;
  font-size: 1.5rem;
  position: fixed;
  top: 50%;
  left: 0;
  text-align: center;
  width: 100%;
  z-index: 1001;
}
