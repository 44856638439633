@import '../../../@theme/variables';

.tag {
  background-color: $brow-1;
  border: 1px solid $brow;
  border-radius: 2px;
  box-sizing: border-box;
  display: inline-block;
  flex-direction: row;
  justify-content: space-between;
  margin: 8px;
  height: 25px;
  width: auto;
  place-self: center stretch;
  &-content {
    align-items: center;
    display: flex;
    justify-content: space-between;
    height: 100%;
    &__text {
      color: $gray-6;
      font-size: 12px;
      height: 15px;
      letter-spacing: -0.33px;
      line-height: 15px;
      margin-left: 8px;
      width: auto;
      white-space: nowrap;
    }
    .close-button i,
    .close i {
      color: $red !important;
      font-size: 15px !important;
      font-weight: normal !important;
      margin-right: 8px;
      margin-left: 8px;
      height: 14.18px;
      width: 10.19px;
    }
  }
}
